import { Outlet } from "react-router-dom";
import LoadDataHelper from "src/components/app/LoadDataHelper";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";

export function AuthRequired() {
  const isAdmin = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.is_admin,
  );
  const preferredDash = useAppSelector(
    (state) => selectPrimaryTrainer(state)?.settings.preferred_dash,
  );
  const exercisesLoaded = useAppSelector(
    (state) => state.app.exerciseStatus === "succeeded",
  );
  const authenticated = useAppSelector((state) => state.trainer.authenticated);

  const coachExists = isAdmin !== undefined;

  if (!coachExists) {
    return <LoadingPage message="Loading trainer" />;
  }

  if (preferredDash && window.location.hostname !== "localhost") {
    if (
      preferredDash === "main" &&
      window.location.hostname !== "trainer.trainwell.net"
    ) {
      window.location.href = window.location.href.replace(
        window.location.host,
        "trainer.trainwell.net",
      );
    } else if (
      preferredDash === "lead" &&
      window.location.hostname !== "leadtrainer.trainwell.net"
    ) {
      window.location.href = window.location.href.replace(
        window.location.host,
        "leadtrainer.trainwell.net",
      );
    } else if (
      preferredDash === "beta" &&
      window.location.host !== "betatrainer.trainwell.net"
    ) {
      window.location.href = window.location.href.replace(
        window.location.host,
        "betatrainer.trainwell.net",
      );
    }
  }

  if (!authenticated) {
    return <LoadingPage message="Authenticating" />;
  }

  return (
    <>
      {exercisesLoaded ? (
        <Outlet />
      ) : (
        <LoadingPage message="Loading exercises" />
      )}
      <LoadDataHelper />
    </>
  );
}
